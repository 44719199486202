<template>
  <section class="section">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12">
          <div class="section-title text-center mb-4 pb-2">
            <SmartLabel tag="span" className="badge badge-pill badge-soft-primary" target="badge" v-model="content.badge"/>
            <SmartLabel tag="h4" className="title mt-3 mb-4" target="title" v-model="content.title"/>
            <SmartLabel tag="p" className="text-muted para-desc mx-auto mb-0" target="subtitle" v-model="content.subtitle"/>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-6 mt-4 pt-2" v-for="(item, key) in content.items" v-bind:key="key">
          <div class="card border-0 text-center features feature-clean rounded">
            <div class="icons text-primary text-center mx-auto">
              <i v-bind:class="`uil uil-${item.icon} d-block rounded h3 mb-0`"></i>
            </div>
            <div class="card-body p-0 mt-4">
              <h5>{{item.title}}</h5>
              <p class="text-muted mb-0">{{item.description}}</p>
              <div class="mt-2">
                <a href="javascript:void(0)" class="text-primary">Read More
                  <arrow-right-icon class="fea icon-sm"></arrow-right-icon></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import { ArrowRightIcon, } from "vue-feather-icons";
  export default {
    name: "WhatWeDo",
    components: {
      ArrowRightIcon,
    },
  };
</script>